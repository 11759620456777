<template>
  <Dyakuyu tenant-alias="aifintech">
    <b-card-header header-bg-variant="white" header-border-variant="0">
      <b-img
        center
        src="/images/finexpres-logo-black.svg"
        style="width: 50%;"
      />
    </b-card-header>

    <b-card-title class="text-center">
      ТОВ “Фінансова компанія “Фінекспрес”
    </b-card-title>
  </Dyakuyu>
</template>

<script>
import Dyakuyu from "@/containers/Dyakuyu.vue";

export default {
  name: "DyakuyuFinexpress",
  components: {
    Dyakuyu
  }
};
</script>
